<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :action-mode="actionMode"
      :title="titleComputed"
    />

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!!contractId">
        <template v-if="!loading">
          <m-form
            ref="form"
            :edit.sync="editMode"
            :form-data.sync="formData"
            :form-info="formInfo"
            class="overflow-y-auto"
            form-provider="mps"
          />
          <!--          <ul v-if="!!formData.attachments">-->
          <!--            <li-->
          <!--              v-for="(att, index) in formData.attachments"-->
          <!--              :key="att"-->
          <!--            >-->
          <!--              <a @click="fileDownload(att)">{{ att.originalFilename }}</a>-->
          <!--            </li>-->
          <!--          </ul>-->
          <group-header :props-data="{ title: this.$translate('프린터 계약') }" />
          <printer-contract-mixed-list
            :contract-id="contractId"
            :account-id="accountId"
          />
        </template>
        <template v-else>
          <progress-loading />
        </template>
      </template>
      <template v-else>
        <invalid-data />
      </template>
    </div>
  </div>
</template>

<script>
import {PATH} from "@/constants";
import InvalidData from "@/components/part/InvalidData";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import ProgressLoading from "@/components/part/ProgressLoading";
import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
import MyToolBar from "@/components/app/toolbar/MyToolBar";
import PrinterContractMixedList from "@/components/windows/printer-contract/loader/PrinterContractMixedList";

const infoKeys = {
  accountName: "accountName",
  accountBranchName: "accountBranchName",
  contractNo: "contractNo",
  contractFee: "contractFee",
  contractType: "contractType",
  realYnName: "realYnName",
  contractStartdate: "contractStartdate",
  contractEnddate: "contractEnddate",
  attachments: "attachments",
  memo: "memo",
};

export default {
  name: "RentalContractDetail",
  components: {GroupHeader, PrinterContractMixedList, MyToolBar, ProgressLoading, InvalidData},
  extends: WindowComponent,
  props: {
    contractId: undefined,
    accountId: undefined,
  },
  data() {
    return {
      loading: false,
      updating: false,
      actionMode: false,
      editMode: false,
      formData: undefined,
      formInfo: [
        {
          component: GroupHeader,
          propsData: {
            title: this.$translate("임대계약정보"),
          },
        },
        {
          mutable: false,
          title: this.$translate("거래처"),
          key: infoKeys.accountName,
          if: (inEditMode, data) => !!data.accountName && !!data.accountId,
          read: (inEditMode, data) => data.accountName + (!!data.accountId ? "[" + data.accountId + "]" : ""),
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("영업점"),
          key: infoKeys.branchName,
          if: (inEditMode, data) => !!data.branchName,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("계약일"),
          key: infoKeys.contractStartdate,
          formReadType: this.$mps.form.types.DATE_PICKER,
          formWriteType: this.$mps.form.types.DATE_PICKER,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("계약종료일"),
          key: infoKeys.contractEnddate,
          formReadType: this.$mps.form.types.DATE_PICKER,
          formWriteType: this.$mps.form.types.DATE_PICKER,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("보증금"),
          key: infoKeys.contractAmount,
          read: (inEditMode, data) => this.$number(data.contractAmount),
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("신규여부"),
          key: infoKeys.contractType,
          read: (inEditMode, data) => data.contractType === "1" ? this.$translate("신규") : data.contractType === "2" ? this.$translate("재계약") : "",
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("정식계약여부"),
          key: infoKeys.realYnName,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("계약 번호"),
          key: infoKeys.contractNo,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("첨부파일"),
          key: infoKeys.attachments,
          read: (inEditMode, data) => data.attachments ,
          formReadType: this.$mps.form.types.FILE_INFOS,
          formWriteType: this.$mps.form.types.FILE_INFOS,
          options: {}
        },
        {
          mutable: false,
          title: this.$translate("비고"),
          key: infoKeys.memo,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {}
        },
      ],
    }
  },
  computed: {
    titleComputed() {
      if (this.loading) return "";
      if (!this.formData) return "";
      return this.formData.contractNo;
    },
  },
  mounted() {
    this.loadDetail();
  },
  methods: {
    async loadDetail(loading = true) {
      this.loading = loading;
      try {
        const response = await this.$request(PATH.CONTRACT.DETAIL)
            .setObject({contractId: this.contractId})
            .enqueue();
        this.formData = response;
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.loading = false;
    },
    async fileDownload(attach) {
      try {
        const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.path)}&filename=${encodeURI(attach.originalFilename)}`;
        console.log(url)
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
        this.$alert("파일 경로가 올바르지 않습니다.");
      }
      // attach.download();

      // window.location.assign(attach.serverFile.path);
      // let byteArray = await attach.download();
      // if (!byteArray) return this.$alert("No File Exists");
      // let a = document.getElementById(attach.id);
      // a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      // a.download = attach.serverFile.originalFilename;
      // a.click();
    }
  }
}
</script>

<style scoped>

</style>