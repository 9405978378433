<template>
  <v-card
    class="ma-0"
    flat
    width="100%"
  >
    <v-list-item
      v-ripple="false"
      three-line
      class="boder-bottom"
    >
      <v-list-item-content>
        <v-list-item-title class="text-left font__17 black--text">
          <!--          {{ item.raw.contractStartdate | date }} ~ {{ item.raw.contractEnddate | date }}-->
          {{ item.raw.regDatetime | date }}
        </v-list-item-title>
        <v-list-item-title
          class="font__14 black--text d-flex justify-space-between"
        >
          <span>{{ item.raw.printerModelName }}</span>
          <span class="font__14">{{ item.raw.rentalTypeName }}</span>
        </v-list-item-title>
        <v-list-item-subtitle
          class="subtitle-2 text-left"
        >
          <span>{{ item.raw.prtSerial }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            justiry="start"
            class="font__13"
          >
            <span>{{ '설치위치' | translate }}: {{ item.raw.installLocation }}</span>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            justify="space-between"
            class="font__13"
          >
            <div
              style="width: 60%;"
              class="text-left"
            >
              <img src="@/assets/images/black_drop.svg"> {{ '기본요금' | translate }}: {{ item.raw.baseFee || 0 }}
            </div>
            <div
              style="width: 40%;"
              class="text-left"
            >
              <img src="@/assets/images/black_drop.svg"> {{ '기본매수' | translate }}: {{ item.raw.basePagecount || 0 }}
            </div>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            justify="space-between"
            class="font__13"
          >
            <div
              style="width: 60%;"
              class="text-left"
            >
              <img src="@/assets/images/black_drop.svg"> {{ '추가요금' | translate }}: {{ item.raw.additionalFee || 0 }}
            </div>
            <div
              style="width: 40%;"
              class="text-left"
            >
              <img src="@/assets/images/black_drop.svg"> {{ '추가매수' | translate }}: {{ item.raw.additionalPagecount || 0 }}
            </div>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            justify="space-between"
            class="font__13"
          >
            <div
              style="width: 60%;"
              class="text-left"
            >
              <img src="@/assets/images/color_drop4.svg"> {{ '기본요금' | translate }}: {{ item.raw.baseFeeColor || 0 }}
            </div>
            <div
              style="width: 40%;"
              class="text-left"
            >
              <img src="@/assets/images/color_drop4.svg"> {{ '기본매수' | translate }}: {{ item.raw.basePagecountColor || 0 }}
            </div>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            justify="space-between"
            class="font__13"
          >
            <div
              style="width: 60%;"
              class="text-left"
            >
              <img src="@/assets/images/color_drop4.svg"> {{ '추가요금' | translate }}: {{ item.raw.additionalFeeColor|| 0 }}
            </div>
            <div
              style="width: 40%;"
              class="text-left"
            >
              <img src="@/assets/images/color_drop4.svg"> {{ '추가매수' | translate }}: {{ item.raw.additionalPagecountColor || 0 }}
            </div>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="installLogShow">
          <printer-contract-install-log-component
            :contract-context="item._printerContract"
            :account-id="accountId"
          />
        </v-list-item-subtitle>
        <v-list-item-subtitle @click.stop="installLogShow = !installLogShow">
          <div
            v-if="installLogShow"
            class="install_log_btn"
          >
            {{ '설치내역 닫기'|translate }}
            <img
              src="@/assets/images/main/svg/acceptAarrow.svg"
              class="ml-2"
              style="transform: rotate(-90deg);"
            >
          </div>
          <div
            v-else
            class="install_log_btn"
          >
            {{ '설치내역 보기' |translate }}
            <img
              src="@/assets/images/main/svg/acceptAarrow.svg"
              class="ml-2"
              style="transform: rotate(90deg);"
            >
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
  import PrinterContractInstallLogComponent from "@/components/items/printer-contract/PrinterContractInstallLogComponent";

    export default {
        name: "PrinterContractItemCard",
        components: {PrinterContractInstallLogComponent},
        props: {
            item: {type: Object, default: undefined},
            options: {
                type: Object, default: () => {
                }
            },
            accountId: undefined
        },
        data() {
          return {
            installLogShow: false,
          }
        },
        computed: {
        },
        mounted() {
          this.item.accountId = this.accountId
        },
        methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.vuexManager.saveProps("SubInformationMain", {titlePassed: item.raw.prtSerial || item.raw.printerModelName});
                this.routerManager.push({
                    name: "PrinterContractInformation", props: {
                        printerContractId: item.raw.printerContractId,
                    }
                });
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .color_icon {
        display: flex;
    }

    .color_icon img {
        margin-right: 3px;
    }

    .install_log_btn{
      height: 30px;
      line-height: 30px;
      background: #fafafa;
      font-weight: bold;
    }
</style>