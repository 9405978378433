export default class PrinterContract {

    _printerContract;

    constructor(printerContract) {
        this._printerContract = printerContract;
    }

    get raw() {
        return this._printerContract;
    }
}