<template>
  <div>
    <template v-if="!loading">
      <template v-if="!!contractId && items.length > 0">
        <printer-contract-list-item-card
          :items="items"
          :account-id="accountId"
        />
      </template>
      <template v-else>
        <no-items
          :title="'프린터 계약이 없습니다.' | translate"
        />
      </template>
    </template>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import PrinterContract from "@/model/printer-contract";
    import PrinterContractListItemCard from "@/components/items/printer-contract/PrinterContractListItemCard";
    import NoItems from "@/components/items/NoItems";

    export default {
        name: "PrinterContractMixedList",
        components: {NoItems, PrinterContractListItemCard},
        props: {
            contractId: null,
            accountId: undefined,
        },
        data() {
            return {
                items: [],
                loading: false,
                alreadyLoaded: false,
            }
        },
        watch: {
            'contractId'(newId) {
                this.processLoadOnce(true);
            },
        },
        mounted() {
            if (!this.alreadyLoaded) {
                this.processLoadOnce();
            }

            this.alreadyLoaded = false;
        },
        created() {
            this.processLoadOnce();
        },
        methods: {
            processLoadOnce() {
                this.loadItems();
                this.alreadyLoaded = true;
            },

            async loadItems() {
                this.loading = true;
                try {
                    const response = await this.$request(PATH.PRINTER_CONTRACT.LIST)
                        .setObject({contractId: this.contractId})
                        .enqueue();
                    this.items = response.data.map(item => new PrinterContract(item));
                    this.$emit('loaded');
                } catch (error) {
                    this.$emit('loaded');
                }
                this.loading = false;
            },
        }
    }
</script>

<style scoped>

</style>