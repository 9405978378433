<template>
  <div>
    <v-row
      v-for="(item) in items"
      :key="item.raw.printerContractId"
      class="mx-0"
    >
      <printer-contract-item-card
        :item="item"
        :account-id="accountId"
      />
    </v-row>
  </div>
</template>

<script>
    import PrinterContractItemCard from "@/components/items/printer-contract/PrinterContractItemCard";

    export default {
        name: "PrinterContractListItemCard",
        components: {PrinterContractItemCard},
        props: {
            items: null,
            accountId: undefined,
        }
    }
</script>

<style scoped>

</style>